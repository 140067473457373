<template>
    <div>
        <h2 style="text-align: center">已注册的子系统</h2>
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleCreate">新增</el-button>
        </div>
        <el-table ref="table" :data="tableData" border width="100%" height="800">
            <el-table-column label="子系统名称" prop="name" align="center"></el-table-column>
            <el-table-column label="统一社会信用代码" prop="socialCreditCode" align="center"></el-table-column>
            <el-table-column label="数据库类型" prop="dbType" align="center"></el-table-column>
        </el-table>

        <el-dialog
            :visible.sync="createDialogVisible"
            title="创建新子系统"
            destroy-on-close
            center
            append-to-body
            width="900" :close-on-click-modal="false">
            <el-form ref="form" :model="createFormModel" :rules="rules" class="x-edit">
                <el-form-item label-width="200px" prop="name" class="w50">
                    <span slot="label">机构名称
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="子系统的名称"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.name"
                              size="small"
                              maxlength="50"
                              show-word-limit/>
                </el-form-item>
                <el-form-item label-width="200px" prop="socialCreditCode" class="w50">
                    <span slot="label">统一社会信用代码+流水
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="机构的统一社会信用代码，不可与现有的机构重复"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.socialCreditCode"
                              size="small"
                              maxlength="30"
                              show-word-limit/>
                </el-form-item>
                <x-divider title="子系统数据库配置"></x-divider>
                <el-form-item label-width="200px" label="数据库类型" prop="dbType" class="line">
                    <el-select placeholder="请选择数据库类型"
                               filterable
                               size="small"
                               v-model="createFormModel.dbType"
                               style="width: 100%">
                        <el-option
                            v-for="dbType in dbTypeOptions"
                            :key="dbType.key"
                            :label="dbType.value"
                            :value="dbType.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="dbUrl" label-width="200px" class="line">
                    <span slot="label">数据库连接路径
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="一个已存在的jdbc url,用于建立连接。必须符合对应数据库的格式，如人大金仓对应url: jdbc:kingbase8://127.0.0.1:54321/DATABASE_NAME"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.dbUrl" size="small" maxlength="200" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label-width="200px" class="w50" prop="dbUsername">
                    <span slot="label">数据库登录账户
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="即将被创建的新系统的登录账户，不可与已存在的用户重复"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.dbUsername" size="small" maxlength="50" show-word-limit/>
                </el-form-item>
                <el-form-item label="数据库登录密码" label-width="200px" class="w50" prop="dbPassword">
                    <el-input v-model="createFormModel.dbPassword" size="small" maxlength="20" show-word-limit/>
                </el-form-item>
                <el-form-item label-width="200px" prop="tablespaceLocation" class="line">
                    <span slot="label">数据库表空间存储路径
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="绝对路径，为将要创建的新系统数据库表空间的存储路径，必须是在目标数据库服务器上已存在的文件目录，且不可与其他系统的表空间路径重复"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.tablespaceLocation" size="small" maxlength="50" show-word-limit/>
                </el-form-item>
                <x-divider title="数据库管理员账户信息"></x-divider>
                <el-form-item prop="adminUsername" label-width="200px" class="w50">
                     <span slot="label">数据库管理员账户
                         <el-tooltip class="item"
                                     effect="dark"
                                     content="用于创建新系统的账户所需的管理员账户，必须为拥有系统管理员权限的账号"
                                     placement="top-start">
                            <i class="el-input__icon el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-input v-model="createFormModel.adminUsername" size="small" maxlength="50" show-word-limit/>
                </el-form-item>
                <el-form-item label="数据库管理员密码" label-width="200px" class="w50" prop="adminPassword">
                    <el-input v-model="createFormModel.adminPassword" size="small" maxlength="50" show-word-limit/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="createDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSubmit">
                    确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {ruleBuilder} from '@/util/validate'
import {createTenant, getRegisteredTenantList, getSupportedDbTypeDictList} from '@/service/manage/multi-tenant';

const DEFAULT_CREATE_FORM_MODEL = {
    name: null,
    socialCreditCode: null,
    dbType: null,
    dbUrl: null,
    dbUsername: null,
    dbPassword: null,
    adminUsername: null,
    adminPassword: null,
    tablespaceLocation: null
};
export default {
    name: "MultiTenantManageList",
    props: ['sessionId'],
    data() {
        const {required, max} = ruleBuilder;
        this.rules = {
            name: [required(), max(50, 'string')],
            socialCreditCode: [required(), max(30, 'string')],
            dbType: [required('string', 'change')],
            dbUrl: [required(), max(200, 'string')],
            dbUsername: [required(), max(50, 'string')],
            dbPassword: [required(), max(20, 'string')],
            adminUsername: [required(), max(50, 'string')],
            adminPassword: [required(), max(20, 'string')],
            tablespaceLocation: [required(), max(100, 'string')],
        };
        return {
            tableData: [],
            createDialogVisible: false,
            createFormModel: {...DEFAULT_CREATE_FORM_MODEL},
            dbTypeOptions: [],
        }
    },
    methods: {
        handleCreate() {
            this.createDialogVisible = true;
            this.createFormModel = {...DEFAULT_CREATE_FORM_MODEL};
        },
        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '创建新机构所需时间较长，请耐心等待...',
                    });
                    createTenant(this.sessionId, this.createFormModel)
                        .then(_ => {
                            this.refresh();
                            this.$msgSuccess("创建成功");
                            this.createDialogVisible = false;
                        })
                        .finally(() => loading.close());
                }
            });
        },
        loadDbTypeOptions() {
            getSupportedDbTypeDictList().then(({data}) => this.dbTypeOptions = data);
        },
        refresh() {
            getRegisteredTenantList(this.sessionId).then(({data}) => this.tableData = data);
        }
    },
    created() {
        this.refresh();
        this.loadDbTypeOptions();
    }
}
</script>

<style scoped>

</style>
